<template>
  <div id="pdfvuer">
    <div
      ref="pdfViewerArea"
      class="pdf-content"
    >
      <div v-if="usesPassword == true">
        <div class="container">
          <div class="card">
            <div class="card-content">
              This PDF is password protected. Initials.app doesn't support password at this moment.
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="usesPassword == false"
        id="pdf-view"
        ref="pdfView"
        class="pdf-view"
      >
        <b-loading
          :is-full-page="true"
          :can-cancel="true"
          :active.sync="isLoading"
        />
        <pdf
          v-for="i in numPages"
          :id="i"
          ref="pdfViewComponent"
          :key="i"
          :src="pdfdata"
          :page="i"
          :scale.sync="scale"
          :page-margin-top="pageOffsetTop"
          :overlays.sync="overlayItems"
          @loading="handleLoadComplete"
          @viewScale="setViewScale"
        >
          <template slot="loading">
            <b-loading
              :is-full-page="true"
              :can-cancel="true"
            />
          </template>
        </pdf>
      </div>
      <div
        v-if="overlayKey != 0"
        id="overlay"
        ref="overlay"
        :key="overlayKey"
        class="overlay"
        :style="{
          height: pdfViewerHeight + 'px',
          width: pdfViewerWidth + 'px',
          'margin-left': pdfMarginLeft,
          top: pdfMarginTop
        }"
      >
        <vue-drag-resize
          v-for="(item, index) in overlayItems"
          ref="signbox"
          :key="index"
          class="resizeable-div-area"
          :parent-limitation="true"
          :overlay-item="item"
          :is-active="true"
          :parent-h="pdfViewerHeight"
          :parent-w="pdfViewerWidth"
          :w="scaleImageWidth(item.width)"
          :h="scaleImageHeight(item.height)"
          :resizable="true"
          :x="item.initialX"
          :y="item.initialY"
          :aspect-ratio="true"
          @resizestop="onOverlayItemChange"
          @dragstop="onOverlayItemChange"
          @onDelete="onOverlayDeleteItem"
        >
          <img
            :src="item.image.src"
            :width="scaleImageWidth(item.width)"
            :height="scaleImageHeight(item.height)"
          >
        </vue-drag-resize>
      </div>
    </div>
  </div>
</template>

<script>
import pdfvuer from "./Pdfvuer";
import VueDragResize from "@/components/VueDragResize";
import { PDFDocument as PDFDocumentGenerator, rgb, degrees } from "pdf-lib";
import { eventHub } from "@/commons/event-bus";

import { fetch as fetchPolyfill } from "whatwg-fetch";
import axios from "axios";

const DEFAULT_SCALE = 1.0;
const CSS_UNITS = 96 / 72;

export default {
  components: {
    pdf: pdfvuer,
    VueDragResize
  },
  props: {
    overlays: {
      type: Array,
      required: true
    },
    pdfFile: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      pdfViewScale: 1,
      pdfViewerHeight: 100,
      pdfViewerWidth: 100,
      pdfMarginLeft: 0,
      pdfMarginTop: 0,
      restrictParent: false,
      overlayKey: 0,
      overlayItems: [],
      isLoading: true,
      currentOverlayItemPage: 1,
      usesPassword: false
    };
  },
  computed: {
    formattedZoom() {
      return parseInt(this.scale * 100);
    },
    pageOffsetTop() {
      // generally the top is negative so we add - to the boundingclientrect
      return (
        window.top.scrollY -
        -document.getElementById("pdfvuer").getBoundingClientRect().top
      );
    }
  },
  watch: {
    show: function(s) {
      if (s) {
        this.getPdf();
      }
    },
    overlays(value) {
      this.overlayItems = value;
    },
    page: function(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    }
  },
  mounted() {
    this.getPdf();
    window.addEventListener("scroll", () => {});
  },
  beforeDestroy() {},
  methods: {
    setViewScale(scale) {
      this.pdfViewScale = scale;
    },
    scaleImageHeight(height) {
      return height * this.pdfViewScale * 0.3;
    },
    scaleImageWidth(width) {
      return width * this.pdfViewScale * 0.3;
    },
    getPdf() {
      var self = this;
      self.pdfdata = pdfvuer.createLoadingTask(this.pdfFile);
      self.pdfdata
        .then(pdf => {
          self.numPages = pdf.numPages;

          window.onscroll = function() {
            this.changePage();
          }.bind(this);
        })
        .catch(function(err) {
          if (err.name == "PasswordException") {
            self.usesPassword = true;
            self.$emit("handlePdfMeta", "password_protected");
          }
        });
    },
    findPos(obj) {
      return obj.offsetTop;
    },
    changePage() {
      var i = 1,
        count = Number(this.numPages);

      do {
        if (
          window.pageYOffset >= this.findPos(document.getElementById(i)) &&
          window.pageYOffset <= this.findPos(document.getElementById(i + 1))
        ) {
          self.page = i;
        }
        i++;
      } while (i < count);
      if (window.pageYOffset >= this.findPos(document.getElementById(i))) {
        self.page = i;
      }
    },
    findOverlayItemPage(rect) {
      // When a overlay item is changed in the page, it's important to find the page the
      // item is in. Page number is required to convert the screen pixel to pdf co-ordinates
      let i = 1; // Page number starts with 1
      const count = Number(this.numPages);
      // Do while is used because of the last page index
      if (count == 1) {
        this.currentOverlayItemPage = 1;
        return;
      }
      do {
        if (
          rect.top + rect.height >= this.findPos(document.getElementById(i)) &&
          rect.top + rect.height <= this.findPos(document.getElementById(i + 1))
        ) {
          this.currentOverlayItemPage = i;
        }
        i++;
      } while (i < count);
      if (rect.top + rect.height >= this.findPos(document.getElementById(i))) {
        this.currentOverlayItemPage = i;
      }
    },
    onOverlayDeleteItem(item) {
      this.$emit("onDeleteOverlay", item, this.overlayItems);
    },
    onOverlayItemChange(newRect, item) {
      this.findOverlayItemPage(newRect);
      let val = this.getPdfCoordinates(this.currentOverlayItemPage, newRect);
      item.x = val.x;
      item.y = val.y;
      item.screenX = val.screenX;
      item.screenY = val.screenY;
      item.pageNumber = this.currentOverlayItemPage;
      item.overlayRect = newRect;
      const index = this.overlayItems.findIndex(x => x.id === item);
      this.overlayItems[index] = item;
    },
    handleLoadComplete(isLoading) {
      if (!isLoading) {
        // The overlay div is used to overlay signatures on PDF. This overlay div's height
        // and width are known only after the PDF is rendered. So we render the `overlay` div
        // after the initialization of the PDF
        const canvasPages = document.getElementsByClassName("page");
        let page;
        if (canvasPages.length > 0) {
          page = canvasPages[0];
          this.pdfViewerHeight = this.$refs.pdfView.clientHeight;
          this.pdfViewerWidth = page.clientWidth; // With is crucial for all the different layouts
          this.pdfMarginLeft = this.$refs.pdfView.getBoundingClientRect().left;
          this.pdfMarginTop = this.$refs.pdfView.getBoundingClientRect().top;
          this.overlayKey++;
          this.isLoading = false;
          this.$emit("handlePdfLoading", false);
        }
        this.isLoading = false;
      }
    },
    submitSignature() {},
    async onFinish() {
      return await this.generatePdf();
    },
    computePageOffset(pageNumber) {
      var rect = document.getElementById(pageNumber).getBoundingClientRect();
      return {
        top: rect.top + window.top.scrollY - this.pageOffsetTop,
        left: 0 //rect.left - 95.25
      };
    },
    getPdfCoordinates(pageNumber, boxRect) {
      let ost = this.computePageOffset(pageNumber);

      let cmp = this.$refs.pdfViewComponent.find(o => o.page == pageNumber);
      let x = 0;
      let y = 0;
      if (cmp.pdfViewer.viewport.rotation == 90) {
        // Pdf is in landscape mode
        x = boxRect.left - ost.left;
        let boxBot = boxRect.top;
        y = Math.abs(boxBot) - ost.top;
      } else {
        // PDF is in potrait
        x = boxRect.left - ost.left;
        let boxBot = boxRect.top + boxRect.height;
        y = Math.abs(boxBot) - ost.top;
      }
      let x_y = cmp.pdfViewer.viewport.convertToPdfPoint(x, y);
      let pdfx = x_y[0];
      let pdfy = x_y[1];
      // screenX and screenY parameters are important for maintaining the position of
      // the overlay on the screen if there are some changes to it. like deletion
      return { x: pdfx, y: pdfy, screenX: x, screenY: boxRect.top };
    },
    getPdfXY(pageNumber, x1, y1) {
      let ost = this.computePageOffset(pageNumber);
      let x = x1 - ost.left;
      let y = y1 - ost.top;
      let cmp = this.$refs.pdfViewComponent.find(o => o.page == pageNumber);
      let x_y = cmp.pdfViewer.viewport.convertToPdfPoint(x, y);
      x = x_y[0];
      y = x_y[1];
      return { x: x, y: y };
    },

    // When calculating the height and width of the signature box,
    // it's kind of trickerier. Based on the rotation angle they change
    //
    // x1,y1                             x3,y3
    //   +---------------------------------+
    //   |                                 |
    //   |                                 |
    //   |                                 |
    //   |                                 |
    //   |                                 |
    //   |                                 |
    //   |                                 |
    //   +---------------------------------+
    // x2,y2                             x4,y4
    //
    // The signature happens only in 0 degree ( Normal View). But, there are cases
    // where one or more pages in a file could be rotated in different angles. These angles
    // could either be in 90, 180, 270, 360. 0 and 360 angles are same. But for other angles,
    // we need to rotate the signature image and the rectangle of the signature image appropriately.
    // During the rotation, the height, width and axis of rotation position of the signature image is changed
    // for the pdf-lib.js. This function is where all the rotation of the images is calculated.
    //
    // All the points are calculated on PDF coordinates.
    getPdfWH(item, degree) {
      let pageNumber = item.pageNumber;
      let boxRect = item.overlayRect;
      let x1y1 = this.getPdfXY(pageNumber, boxRect.left, boxRect.top);
      let x2y2 = this.getPdfXY(
        pageNumber,
        boxRect.left,
        boxRect.top + boxRect.height
      );
      let x4y4 = this.getPdfXY(
        pageNumber,
        boxRect.left + boxRect.width,
        boxRect.top
      );
      let x3y3 = this.getPdfXY(
        pageNumber,
        boxRect.left + boxRect.width,
        boxRect.top + boxRect.height
      );
      if (degree == 0) {
        const width = x4y4.x - x2y2.x;
        const height = x1y1.y - x2y2.y;
        const x = item.x;
        const y = item.y;
        const rotation = 0;
        return { width, height, x, y, rotation };
      } else if (degree == 90) {
        const height = x2y2.x - x1y1.x;
        const width = x3y3.y - x1y1.y;
        const x = item.x + height;
        const y = item.y;
        const rotation = 90;
        return { width, height, x, y, rotation };
      } else if (degree == 180) {
        const width = x4y4.x - x2y2.x;
        const height = x1y1.y - x2y2.y;
        const x = item.x;
        const y = item.y;
        const rotation = 0;
        return { width, height, x, y, rotation };
      } else if (degree == 270) {
        const height = x2y2.x - x1y1.x;
        const width = x3y3.y - x1y1.y;
        const x = item.x;
        const y = item.y;
        const rotation = 90;
        return { width, height, x, y, rotation };
      }
    },
    async generatePdf() {
      const arrayBuffer = await fetch(this.pdfFile)
        .then(res => {
          if (res == undefined) {
            eventHub.$emit("document.submitted", false);
            return;
          }
          return res.arrayBuffer();
        })
        .catch(function(ex) {
          eventHub.$emit("document.submitted", false);
          return null;
        });

      if (arrayBuffer == null) return;

      const pdfDoc = await PDFDocumentGenerator.load(arrayBuffer, {
        ignoreEncryption: false
      }).catch(function(e) {
        return;
      });

      for (let index = 0; index < this.overlays.length; index++) {
        const item = this.overlays[index];

        let cmp = this.$refs.pdfViewComponent.find(
          o => o.page == item.pageNumber
        );
        // Rotation angle is used to calculate the height and width of signature overlay
        let rotationAngle = cmp.pdfViewer.viewport.rotation;

        const pngImageBytes = await fetch(item.image.src).then(res =>
          res.arrayBuffer()
        );
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const imageMeta = this.getPdfWH(item, rotationAngle);

        // page index is different in pdf-lib
        const page = pdfDoc.getPage(item.pageNumber - 1);

        page.drawImage(pngImage, {
          x: imageMeta.x,
          y: imageMeta.y,
          width: imageMeta.width,
          height: imageMeta.height,
          rotate: degrees(imageMeta.rotation)
        });
      }
      const pdfDataUri = await pdfDoc.save();

      // Code is used for debugging purpose. This is used when we
      // want to save the changes to pdf and test it.

      // const blob = new Blob([pdfDataUri], { type: "application/pdf" });
      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(blob);
      // link.download = "SDFSDF.pdf";
      // link.click();
      // URL.revokeObjectURL(link.href);

      // let a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style = "display: none";

      // Enable this for production
      const blob = new Blob([pdfDataUri], { type: "application/pdf" });
      return blob;
    }
  }
};
</script>

<style lang="scss" scoped>
$width-percent: 90%;
$pdf-margin: 0 auto;
$pdf-background: #fff; //#f5f5f5f5;
$pdf-border-testing: 0px solid transparent;

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  $width-percent: 50%;
  .pdf-content {
    border: 4px solid rgb(4, 8, 255);
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  $width-percent: 76%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  $width-percent: 94%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* ----------- iPad Pro ----------- /
/ Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  $width-percent: 94%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  $width-percent: 94%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* Landscape */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  $width-percent: 68%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  $width-percent: 94%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  $width-percent: 98%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  $width-percent: 100%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

@media only screen and (min-width: 1024px) {
  $width-percent: 70%;
  .pdf-content {
    border: $pdf-border-testing;
    height: 100%;
    min-height: 1000px;
    background: $pdf-background;
    margin: $pdf-margin;
    width: $width-percent;
    position: relative;
  }
}

#pdfvuer {
  margin-top: 20px;
}

.pdf-view {
  z-index: -1;
}
.overlay {
  position: absolute;
  z-index: 1;
  margin: $pdf-margin;
  min-height: 100px;
  /* padding: 10px; */
  height: 100%;
  top: 0;
  left: 0;
  width: $width-percent;
  // border: 1px solid red;
}

.drag-handle,
.drag-cancel {
  padding: 6px;
  margin: 6px;
  background-color: #ccc;
  border: 2px solid;
}

.drag-handle:hover {
  cursor: move;
}

.drag-cancel:hover {
  cursor: not-allowed;
}

.toolbar {
  background: #ffffff;
}
</style>

<style></style>
