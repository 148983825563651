var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "pdfvuer" } }, [
    _c("div", { ref: "pdfViewerArea", staticClass: "pdf-content" }, [
      _vm.usesPassword == true ? _c("div", [_vm._m(0)]) : _vm._e(),
      _vm.usesPassword == false
        ? _c(
            "div",
            {
              ref: "pdfView",
              staticClass: "pdf-view",
              attrs: { id: "pdf-view" }
            },
            [
              _c("b-loading", {
                attrs: {
                  "is-full-page": true,
                  "can-cancel": true,
                  active: _vm.isLoading
                },
                on: {
                  "update:active": function($event) {
                    _vm.isLoading = $event
                  }
                }
              }),
              _vm._l(_vm.numPages, function(i) {
                return _c(
                  "pdf",
                  {
                    key: i,
                    ref: "pdfViewComponent",
                    refInFor: true,
                    attrs: {
                      id: i,
                      src: _vm.pdfdata,
                      page: i,
                      scale: _vm.scale,
                      "page-margin-top": _vm.pageOffsetTop,
                      overlays: _vm.overlayItems
                    },
                    on: {
                      "update:scale": function($event) {
                        _vm.scale = $event
                      },
                      "update:overlays": function($event) {
                        _vm.overlayItems = $event
                      },
                      loading: _vm.handleLoadComplete,
                      viewScale: _vm.setViewScale
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "loading" },
                      [
                        _c("b-loading", {
                          attrs: { "is-full-page": true, "can-cancel": true }
                        })
                      ],
                      1
                    )
                  ],
                  2
                )
              })
            ],
            2
          )
        : _vm._e(),
      _vm.overlayKey != 0
        ? _c(
            "div",
            {
              key: _vm.overlayKey,
              ref: "overlay",
              staticClass: "overlay",
              style: {
                height: _vm.pdfViewerHeight + "px",
                width: _vm.pdfViewerWidth + "px",
                "margin-left": _vm.pdfMarginLeft,
                top: _vm.pdfMarginTop
              },
              attrs: { id: "overlay" }
            },
            _vm._l(_vm.overlayItems, function(item, index) {
              return _c(
                "vue-drag-resize",
                {
                  key: index,
                  ref: "signbox",
                  refInFor: true,
                  staticClass: "resizeable-div-area",
                  attrs: {
                    "parent-limitation": true,
                    "overlay-item": item,
                    "is-active": true,
                    "parent-h": _vm.pdfViewerHeight,
                    "parent-w": _vm.pdfViewerWidth,
                    w: _vm.scaleImageWidth(item.width),
                    h: _vm.scaleImageHeight(item.height),
                    resizable: true,
                    x: item.initialX,
                    y: item.initialY,
                    "aspect-ratio": true
                  },
                  on: {
                    resizestop: _vm.onOverlayItemChange,
                    dragstop: _vm.onOverlayItemChange,
                    onDelete: _vm.onOverlayDeleteItem
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: item.image.src,
                      width: _vm.scaleImageWidth(item.width),
                      height: _vm.scaleImageHeight(item.height)
                    }
                  })
                ]
              )
            }),
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-content" }, [
          _vm._v(
            " This PDF is password protected. Initials.app doesn't support password at this moment. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }