var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "document-dialog",
      style: { width: _vm.$responsive.width + "px" },
      attrs: { active: _vm.dialog, scroll: "keep", "can-cancel": false },
      on: {
        "update:active": function($event) {
          _vm.dialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: " dialog modal-card", staticStyle: { width: "auto" } },
        [
          _c("header", { staticClass: "modal-card-head" }, [
            _c(
              "p",
              { staticClass: "modal-card-title" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "button is-pulled-right",
                    attrs: {
                      "icon-left": "close",
                      type: "button",
                      rounded: "",
                      size: "is-small"
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleClose()
                      }
                    }
                  },
                  [_vm._v(" Close ")]
                ),
                _vm._v(" Sign ")
              ],
              1
            )
          ]),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c("VueSignaturePad", {
                ref: "signaturePad",
                staticClass: "signature-pad",
                style: {
                  height: _vm.canvasHeight + "px"
                }
              })
            ],
            1
          ),
          _c("footer", { staticClass: "modal-card-foot" }, [
            _c("div", { staticClass: "level action-buttons" }, [
              _c(
                "div",
                { staticClass: "level-item is-pulled-left" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button",
                      attrs: {
                        "icon-left": "eraser",
                        type: "button",
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.clear()
                        }
                      }
                    },
                    [_vm._v(" Clear ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "level-item is-pulled-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        "icon-left": "content-save-edit-outline",
                        loading: _vm.isSavingProgress,
                        rounded: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }