<template>
  <div class="pdf-wrapper">
    <slot
      v-if="loading"
      name="loading"
    />
    <div class="pdf-container">
      <div
        id="viewerContainer"
        ref="container"
        class="view-container"
        @click="mouseHandler"
      >
        <div
          id="viewer"
          class="pdfViewer"
        />

        <resizeSensor
          :initial="true"
          @resize="resizeScale"
        />
      </div>
    </div>
  </div>
</template>
<script>
import "pdfjs-dist/web/pdf_viewer.css";
import pdfjsLib from "pdfjs-dist/webpack.js";
import _ from "lodash";
import {
	DefaultAnnotationLayerFactory,
	DefaultTextLayerFactory,
	PDFLinkService,
	PDFPageView
} from "pdfjs-dist/web/pdf_viewer.js";
import resizeSensor from "vue-resize-sensor";

function isPDFDocumentLoadingTask(obj) {
	return (
		typeof obj === "object" &&
		obj !== null &&
    obj.__PDFDocumentLoadingTask === true
	);
}

function createLoadingTask(src, options) {
	var source;
	if (typeof src === "string") source = { url: src };
	else if (typeof src === "object" && src !== null) source = _.extend({}, src);
	else throw new TypeError("invalid src type");

	var loadingTask = pdfjsLib.getDocument(source).promise;
	loadingTask.__PDFDocumentLoadingTask = true; // since PDFDocumentLoadingTask is not public

	if (options && options.onPassword)
		loadingTask.onPassword = options.onPassword;

	if (options && options.onProgress)
		loadingTask.onProgress = options.onProgress;

	return loadingTask;
}

export default {
	createLoadingTask: createLoadingTask,
	components: {
		resizeSensor
	},
	props: {
		src: {
			type: [String, Object, Promise],
			default: ""
		},
		page: {
			type: Number,
			default: 1
		},
		rotate: {
			type: Number,
			default: 0
		},
		pageMarginTop: {
			type: Number,
			default: 0
		},
		scale: {
			type: [Number, String],
			default: "page-width"
		},
		resize: {
			type: Boolean,
			default: false
		},
		annotation: {
			type: Boolean,
			default: false
		},
		text: {
			type: Boolean,
			default: true
		},
		overlays: {
			type: Array,
			required: true
		}
	},
	data: function() {
		return {
			internalSrc: this.src,
			pdf: null,
			pdfViewer: null,
			loading: true,
			scrollTop: 0,
			signRect: null
		};
	},
	watch: {
		pdf: function(val) {
			var pdfInfo = val.pdfInfo || val._pdfInfo;
			this.$emit("numpages", pdfInfo.numPages);
		},
		page: function(val) {
			var self = this;
			this.pdf.getPage(val).then(function(pdfPage) {
				self.pdfViewer.setPdfPage(pdfPage);
				self.pdfViewer.draw();
			});
		},
		scale: function(val) {
			this.drawScaled(val);
		},
		rotate: function(newRotate) {
			if (this.pdfViewer) {
				this.pdfViewer.update(this.scale, newRotate);
				this.pdfViewer.draw();
			}
		}
	},
	mounted: function() {
		window.addEventListener("scroll", this.onscroll);
		this.loadPDF();
	},
	beforeDestroy() {
		window.removeEventListener("scroll", this.onscroll);

		var self = this;
		if (self.pdfViewer) {
			self.pdfViewer.destroy();
			self.pdfViewer = null;
		}
	},
	methods: {
		loadPDF() {
			var self = this;
			if (!isPDFDocumentLoadingTask(self.internalSrc)) {
				self.internalSrc = createLoadingTask(self.internalSrc);
				self.$emit("loading", true);
			}

			var container = this.$refs.container;

			var pdfLinkService = new PDFLinkService();

			let annotationLayer = undefined,
				textLayer = undefined;
			if (self.annotation) {
				annotationLayer = new DefaultAnnotationLayerFactory();
			}
			if (self.text) {
				textLayer = new DefaultTextLayerFactory();
			}
			var DEFAULT_SCALE = 1.0;
			var CSS_UNITS = 96 / 72;

			self.internalSrc
				.then(function(pdfDocument) {
					// Document loaded, retrieving the page.
					self.pdf = pdfDocument;
					return pdfDocument.getPage(self.page).then(function(pdfPage) {
						var viewport = pdfPage.getViewport({ scale: 1 });
						var scale = container.clientWidth / (viewport.width * CSS_UNITS);
						// Create the page view.
						var pdfPageView = new PDFPageView({
							container: container,
							id: self.page,
							scale: scale,
							defaultViewport: viewport,
							annotationLayerFactory: annotationLayer
						});
						self.pdfViewer = pdfPageView;
						// Associate the actual page with the view and draw it.
						pdfPageView.setPdfPage(pdfPage);
						if (self.pdf._pdfInfo.numPages == self.page) {
							self.$emit("loading", false);
							self.$emit("viewScale", scale);
						}
						return pdfPageView.draw();
					});
				})
				.then(function(pdfPage) {
					// Creating the page view with default parameters.
					self.pdfViewer = new PDFPageView({
						container: container,
						id: self.page,
						scale: 1,
						defaultViewport: pdfPage.getViewport({ scale: 1 }),
						// We can enable text/annotations layers, if needed
						textLayerFactory: textLayer,
						annotationLayerFactory: annotationLayer
					});
					// Associates the actual page with the view, and drawing it
					self.pdfViewer.setPdfPage(pdfPage);
					pdfLinkService.setViewer(self.pdfViewer);
					self.drawScaled(self.scale);
				})
				.catch(err => self.$emit("error", err));
		},
		onscroll() {
			this.scrollTop = window.top.scrollY;
		},
		calculateScale: function(width = -1, height = -1) {
			this.pdfViewer.update(1, this.rotate); // Reset scaling to 1 so that "this.pdfViewer.viewport.width" gives proper width;
			if (width === -1 && height === -1) {
				width = this.$refs.container.offsetWidth;
			}

			return width / this.pdfViewer.viewport.width;
		},
		drawScaled: function(newScale) {
			if (this.pdfViewer) {
				if (newScale === "page-width") {
					newScale = 1.2; //this.calculateScale();
					this.$emit("update:scale", newScale);
				}
				this.pdfViewer.update(newScale, this.rotate);
				this.pdfViewer.draw();
				this.loading = false;
				this.$emit("loading", false);
			}
		},
		resizeScale: function() {
			if (this.resize) {
				this.drawScaled("page-width");
			}
		},
		mouseHandler(event) {
			let pageId = "page" + this.page;
			let pg = document.getElementById(pageId);
			this.onPositionSelect(event, pg);
		},
		onPositionSelect(event, canvasElement) {
			let ost = this.computePageOffset(canvasElement);
			let x = event.pageX - ost.left;
			let y = event.pageY - ost.top;
			let x_y = this.pdfViewer.viewport.convertToPdfPoint(x, y);
			x = x_y[0];
			y = x_y[1];
		},
		computePageOffset(canvasElement) {
			var rect = document.getElementById("pdf-view").getBoundingClientRect();
			var vrect = document
				.getElementById("viewerContainer")
				.getBoundingClientRect();
			let bodyElt = document.getElementById("topDiv");
			return {
				top: rect.top + window.top.scrollY - 80,
				left: 0 //rect.left - 95.25
			};
		}
	}
};
</script>
<style scoped>
canvas {
	padding-right: 3px;
}

.pdf-container {
	background-color: white;
	margin-bottom: 20px;
}
.page {
	background-color: white;
}
</style>
<style>
.page {
	-webkit-box-shadow: 0px 0px 10px 2px rgba(181, 181, 181, 1);
	-moz-box-shadow: 0px 0px 10px 2px rgba(181, 181, 181, 1);
	box-shadow: 0px 0px 10px 2px rgba(181, 181, 181, 1);
}
</style>
