var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pdf-wrapper" },
    [
      _vm.loading ? _vm._t("loading") : _vm._e(),
      _c("div", { staticClass: "pdf-container" }, [
        _c(
          "div",
          {
            ref: "container",
            staticClass: "view-container",
            attrs: { id: "viewerContainer" },
            on: { click: _vm.mouseHandler }
          },
          [
            _c("div", { staticClass: "pdfViewer", attrs: { id: "viewer" } }),
            _c("resizeSensor", {
              attrs: { initial: true },
              on: { resize: _vm.resizeScale }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }