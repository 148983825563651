<template>
  <b-modal
    :active.sync="dialog"
    scroll="keep"
    :can-cancel="false"
    class="document-dialog"
    :style="{ 'width': $responsive.width + 'px'}"
  >
    <div
      class=" dialog modal-card"
      style="width: auto"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b-button
            icon-left="close"
            class="button is-pulled-right"
            type="button"
            rounded
            size="is-small"
            @click="handleClose()"
          >
            Close
          </b-button>
          Sign
        </p>
      </header>
      <section class="modal-card-body">
        <VueSignaturePad
          ref="signaturePad"
          class="signature-pad"
          :style="{
            height: canvasHeight + 'px',
          }"
        />
      </section>
      <footer class="modal-card-foot">
        <div class="level action-buttons">
          <div class="level-item is-pulled-left">
            <b-button
              icon-left="eraser"
              class="button"
              type="button"
              rounded
              @click="clear()"
            >
              Clear
            </b-button>
          </div>
          <div class="level-item is-pulled-right">
            <b-button
              icon-left="content-save-edit-outline"
              class="button is-primary"
              :loading="isSavingProgress"
              rounded
              @click="save()"
            >
              Save
            </b-button>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    visibility: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      dialog: false,
      isSavingProgress: false,
      isDeletingProgress: false,
      selectedModuleIdToMove: -1,
      modules: []
    };
  },
  computed: {
    canvasHeight() {
      if (window.innerWidth / 2 > 300) {
        return 300; 
      }else {
        return window.innerWidth / 2
      }
    }
  },
  watch: {
    visibility(value) {
      this.dialog = value;
    }
  },
  methods: {
    handleClose() {
      this.dialog = false;
      this.$emit("handleDocumentCloseDialog", this.dialog, null);
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    save() {
      // eslint-disable-next-line no-unused-vars
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(isEmpty) {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Please add your initials."
          });
          return;
      }
      this.$emit("handleDocumentCloseDialog", this.dialog, data);
    }
  }
};
</script>

<style>
.document-dialog {
  z-index: 1;
}

.modal {
  z-index: 100;
}
.document-dialog > .modal-background {
  background-color: transparent;
}
.modal-content {
  box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -moz-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -webkit-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  max-width: 650px !important;
}

.modal-card-head,
.modal-card-foot {
  background-color: #ffffff !important;
}
.modal-card-foot {
  padding: 5px;
}

.modal-card-foot {
  border-top: none;
}
.modal-card-head {
  border-bottom: none;
}

.action-buttons {
  width: 100%;
}
.signature-pad {
  border: 1px solid #cccccc;
}
/* .modal-background {
  margin: 0;
  height: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
} */
</style>