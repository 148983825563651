var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vdr",
      class: _vm.active || _vm.isActive ? "active" : "inactive",
      style: _vm.style,
      on: {
        mousedown: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.bodyDown($event)
        },
        touchstart: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.bodyDown($event)
        }
      }
    },
    [
      _vm._t("default"),
      _vm._l(_vm.sticks, function(stick) {
        return _c("div", {
          key: stick,
          staticClass: "vdr-stick",
          class: ["vdr-stick-" + stick, _vm.isResizable ? "" : "not-resizable"],
          style: _vm.vdrStick(stick),
          on: {
            mousedown: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.stickDown(stick, $event)
            },
            touchstart: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.stickDown(stick, $event)
            }
          }
        })
      }),
      _c(
        "div",
        { staticClass: "vdr-stick button", style: _vm.vdrStickButton("tr") },
        [
          _c("b-button", {
            staticStyle: { left: "-10px", top: "-10px" },
            attrs: { rounded: "", type: "is-dark", "icon-right": "close" },
            on: { click: _vm.deleteDiv }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }